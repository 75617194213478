import type { query } from "@fscrypto/domain";
import { generateRandomName } from "@fscrypto/domain";
import type { LoaderFunctionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { useSubmit } from "@remix-run/react";
import { useEffect } from "react";
import { $path } from "remix-routes";
import invariant from "tiny-invariant";
import { services } from "~/services/services.server";

export const loader = async ({ context, request }: LoaderFunctionArgs) => {
  const user = await services.auth.getCurrentUser(request);
  if (!user) return {};

  const profileId = user.currentProfileId || user.profileId;

  const newQuery: query.QueryNew = {
    name: generateRandomName(),
    queryCollectionId: null,
  };

  // execute
  const q: query.Query = await services.queries.create(newQuery, user.id, profileId);
  invariant(q, "query not created");
  return redirect($path("/studio/queries/:id", { id: q.id }));
};

export default function NewQuery() {
  const submit = useSubmit();
  useEffect(() => {
    submit(null, { method: "post", action: $path("/auth/auth0", { redirectUrl: $path("/new-query") }) });
  }, [submit]);
  return <></>;
}
